<template>
    <BT-Blade
        :canClose="false"
        :canDelete="false"
        :canEdit="false"
        :canRefresh="false"
        hideBackButton
        title="Procurement Dashboard">
        <template v-slot>
            <v-container dense fluid>
                <v-row>
                    <v-col cols="12" sm="6" md="4">
                        <BT-Blade-Items
                            height="300"
                            hideBladeHeader
                            hideHeader
                            navigation="products"
                            :onPullSuccessAsync="pullStatus"
                            :params="{ filterBy: 'Acquired' }"
                            showList>
                            <template v-slot:body="{ items }">
                                <span class="ml-3 my-auto">Stock Status</span>
                                <v-list>
                                    <v-card v-for="(card, index) in items" :key="index" class="mb-3">
                                        <v-list-item>
                                            <v-list-item-icon class="mx-0">
                                                <v-icon v-if="(card.percent > 50)" class="my-auto" color="success" small>mdi-check-circle</v-icon>
                                                <v-icon v-else :color="card.color" class="my-auto" small>mdi-alert</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <BT-Entity
                                                        navigation="products"
                                                        :itemValue="card.productID"
                                                        itemText="productName" />
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn small icon>
                                                    <v-icon small>mdi-cart</v-icon>
                                                </v-btn>
                                                <!-- {{ card.percent / 100 | toPercent }} -->
                                            </v-list-item-action>
                                        </v-list-item>
                                        <v-progress-linear
                                            :color="card.color"
                                            :value="card.percent"
                                            height="15">
                                            <template v-slot>
                                                <span class="ml-2">{{ card.balance | toDisplayNumber }}</span>
                                                <v-spacer />
                                                <span>{{ card.percent / 100 | toPercent }}</span>
                                                <v-spacer />
                                                <span class="mr-2">{{ card.upperThreshold | toDisplayNumber }}</span>
                                            </template>
                                        </v-progress-linear>
                                    </v-card>
                                </v-list>
                            </template>
                        </BT-Blade-Items>
                    </v-col>
                    <v-col cols="12" sm="6" md="2" class="ma-0 pa-0">
                        <v-row class="ma-0 pa-0">
                            <v-col cols="6" md="12">
                                <BT-Graph-Card
                                    v-if="isLengthyArray(statusItems)"
                                    :canExportCSV="false"
                                    circleSize="65"
                                    circleFontSize="16px"
                                    color="error"
                                    defaultGraphType="Total"
                                    defaultXSpan="All"
                                    defaultXSpread="Count"
                                    :height="120"
                                    hideHorizontals
                                    hideLegend
                                    hideSpanOptions
                                    hideXLabels
                                    hideYLabels
                                    :items="statusItems"
                                    :itemText="null"
                                    :onFilter="list => list.filter(z => z.percent <= 25)"
                                    :showSettings="false"
                                    :showFilters="false"
                                    title="SKU Dangers"
                                    wholeNumbers />
                            </v-col>
                            <v-col cols="6" md="12">
                                <BT-Graph-Card
                                    v-if="isLengthyArray(statusItems)"
                                    :canExportCSV="false"
                                    circleSize="65"
                                    circleFontSize="16px"
                                    color="warning"
                                    defaultGraphType="Total"
                                    defaultXSpan="All"
                                    defaultXSpread="Count"
                                    :height="120"
                                    hideHorizontals
                                    hideLegend
                                    hideSpanOptions
                                    hideXLabels
                                    hideYLabels
                                    :items="statusItems"
                                    :itemText="null"
                                    :onFilter="list => list.filter(z => z.percent > 25 && z.percent <= 50)"
                                    :showSettings="false"
                                    :showFilters="false"
                                    title="SKU Warnings"
                                    wholeNumbers />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <BT-Graph-Card
                            :canExportCSV="false"
                            circleSize="100"
                            circleFontSize="18px"
                            defaultGraphType="Total"
                            defaultXSpan="All"
                            defaultXSpread="Count"
                            :headers="[
                                { text: 'CO#', value: 'customerOrderNumber' },
                                { text: 'Due', value: 'dueDate', textFilter: 'toDayMonth' },
                                { text: 'Supplier', value: 'seller.companyName' }]"
                            :height="170"
                            hideActions
                            hideHorizontals
                            hideLegend
                            hideSpanOptions
                            hideXLabels
                            hideYLabels
                            :itemsPerPage="5"
                            :itemProperties="['ID','CustomerOrderNumber','DueDate','Seller','AmountTotal']"
                            :itemText="null"
                            navigation="purchase-orders"
                            :showFilters="false"
                            :showSettings="false"
                            showTable
                            title="Orders"
                            wholeNumbers />
                    </v-col>
                    <v-col cols="12" sm="6" lg="3">
                        <BT-Graph-Card
                            addBladeName="supplier"
                            canAdd
                            :canExportCSV="false"
                            circleSize="100"
                            circleFontSize="18px"
                            defaultGraphType="Total"
                            defaultXSpan="All"
                            defaultXSpread="Count"
                            :headers="[
                                { text: 'Supplier Name', value: 'seller.companyName' }]"
                            :height="170"
                            hideActions
                            hideHorizontals
                            hideLegend
                            hideSpanOptions
                            hideXLabels
                            hideYLabels
                            :itemsPerPage="5"
                            :itemProperties="['ID','Seller']"
                            :itemText="null"
                            navigation="suppliers"
                            :showSettings="false"
                            :showFilters="false"
                            showTable
                            title="Suppliers"
                            wholeNumbers>
                            <template v-slot:toolbar-right>
                                <v-spacer />
                                <v-btn small text :to="{ name: 'public-suppliers' }">
                                    <v-icon left small>mdi-store-search</v-icon>Find
                                </v-btn>
                                <v-btn small text :to="{ name: 'supplier', params: { id: 'new' } }">
                                    <v-icon left small>mdi-plus</v-icon>New
                                </v-btn>
                            </template>
                        </BT-Graph-Card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </BT-Blade>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'Customer-Dashboard',
    components: {
        BTGraphCard: () => import('~components/BT-Graph-Card.vue')
    },
    data() {
        return {
            statusItems: []
        }
    },
    methods: {
        async pullStatus(pList) {
            // var pRes = await this.$BlitzIt.store.getAll('products', { filterBy: 'Acquired' });
            if (pList == null || pList.length == 0) {
                return [];
            }
            
            var idString = pList.map(z => z.id).toString();
            var list = await this.$BlitzIt.store.getAll('stock-tracked-items-status', { productIDs: idString });

            var transfers = await this.$BlitzIt.store.getAll('stock-consignments', { includeDetails: true, filterBy: 'Transfers' });
            
            transfers = transfers.filter(z => z.performedOn == null && this.isLengthyArray(z.consignmentOrderItems));

            transfers.forEach(transfer => {
                transfer.consignmentOrderItems.forEach(oItem => {
                    var exi = list.find(z => z.productID == oItem.productID);
                    if (exi != null) {
                        exi.consigned -= oItem.quantity;
                    }
                })
            })
         
            var e = list.map(x => {
                var balance = x.inStock + x.onOrder - x.consigned - x.onRequest;
                var percent = 0;
                if (balance == 0) {
                    percent = 0;
                }
                else if (x.upperThreshold > 0) {
                    percent = this.percent(balance, x.upperThreshold);
                }

                return Object.assign({}, x, {
                    color: percent <= 25 ? 'red' : (percent <= 50 ? 'warning' : 'success'),
                    balance: balance,
                    percent: percent
                })
            });
            
            e.sort(firstBy(x => x.percent));

            this.statusItems = e;

            return e;
        }
    }
}
</script>